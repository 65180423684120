import React, { useEffect, useState } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-social/materialize-social.css';
import 'font-awesome/css/font-awesome.min.css';
import './scss/App.scss';
import HomePage from './components/pages/home';
import AboutUsPage from './components/pages/about_us';
import BrowsePage from './components/pages/browse';
import ContactUsPage from './components/pages/contact_us';
import CartPage from './components/pages/cart';
import { CartBasketContext, CurrentPageContext } from './components/contexts';
import BaseHeader from './components/pages/structure/base_header';
import BaseFooter from './components/pages/structure/base_footer';

const App: React.FC = () => {
  const MATERIALIZE = (window as any).M;
  const [cartBasket, setCartBasket] = useState<any>({});
  const [currentPage, setCurrentPage] = useState('home');

  const renderPage = (): JSX.Element | null => {
    switch (currentPage) {
      case 'home':
        return <HomePage />;
      case 'about':
        return <AboutUsPage />;
      case 'browse':
        return <BrowsePage />;
      case 'contact':
        return <ContactUsPage />;
      case 'cart':
        return <CartPage />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (Object.keys(cartBasket).length === 0) {
      const initialValue = JSON.parse(localStorage.getItem('cartBasket') as string) || {
        coffee_machine: 0,
        tubz_machine: 0,
        vending_machine: 0,
      };
      setCartBasket(initialValue);
      localStorage.setItem('cartBasket', (JSON.stringify(initialValue)));
    } else {
      localStorage.setItem('cartBasket', (JSON.stringify(cartBasket)));
    }
  }, [cartBasket]);

  function SignUpForMarketing() {
    fetch("http://localhost:8000/server.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: "John", location: "Boston" }),
    })
      .then(data => {
        console.log(data);
        console.log(data.status);
        MATERIALIZE.toast({ html: `Successfully signed up`, classes: 'green' });
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    SignUpForMarketing();
  }, []);

  return (
    <CartBasketContext.Provider value={{ cartBasket, setCartBasket }}>
      <CurrentPageContext.Provider value={{ currentPage, setCurrentPage }}>
        <div>
          <BaseHeader />
          {renderPage()}
          <BaseFooter />
        </div>
      </CurrentPageContext.Provider>
    </CartBasketContext.Provider>
  );
};

export default App;
