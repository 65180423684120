import React from "react";

export const GenericIcon: React.FC<{
    icon: string;
    classes: string;
    onClick: any;
}> = (props) => {
    return (
        <i className={`material-icons z-depth-2 ${props.classes}`} onClick={props.onClick ? props.onClick : null}>{props.icon}</i>
    );
};